.container{
  width:1400px;
  margin:0 auto;
}
.recharge-banner{
  width:100%;
  height: 280px;
  position: relative;
  margin-top: 36px;
  background-image: url('../../static/image/banner2.png');
  background-size: 100% 280px;
  .box{
    width: 283px;
    height: 131px;
    position: absolute;
    left:50%;
    top:90px;
    margin-left: -141.5px;
    color:#fff;
    text-align: center;

    h1{
      border-bottom: 2px solid #fff;
      font-size: 69px;
      line-height: 75px;
    }

    .desc{
     
      text-align: right;
      font-size: 30px;
    }
  }
}

.role-msg{
    width:1400px;
    margin:45px auto;
    display: flex;
    justify-content: space-between;
    height:160px;
    padding-top: 30px;
    background-color: #F1F1F1;
    position: relative;
  .item{
    width:430px;
    height: 55px;
    border-radius: 20px 0 20px 0;
    border:2px solid #776CD0;
    background-color:#fff;
    overflow: hidden;
    position: absolute;
    font-size: 18px;

    &:nth-child(1){
      left:0;
    }
    &:nth-child(2){
      left:500px;
    }
    &:nth-child(3){
      right:0
    }
    .option{
      width:368px;
      height: 58px;
      margin:0 31px 0 31px;
      color: #776CD0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 26px;
      &.active{
        border-bottom: 2px solid#A9A2E1;
      }
      .icon{
        width:28px;
        height: 19px;
        background-image: url(../../static/image/more.png);
        background-size:28px 19px;
        display: block;

        &.active{
          background-image: url(../../static/image/more_active.png);
        }
        
      }

      span{
        padding-left:22px;
      }


    }

    &.active{
      height: auto;
      background: #776CD0;

      .option{
        color:#fff;

        .icon{
          background-image: url(../../static/image/more_active.png);
        }
        
      }
      .options{
        margin:0;
        width: 100%;
        &:hover{
          color:#776CD0;
          background-color: #fff;
          border-radius: 20px 0 20px 0;
          cursor: pointer;
        }
      }
      
    }
  }
}

.title{
  border-left: 3px solid #776CD0;
  padding-left: 10px;
  font-size: 22px;
  line-height: 14px;
}


.pay-ment{
  .item{
    background-color: #776CD0;
    width: 428px;
    overflow: visible;
    .titleOption{
      background-color: #Fff;
      margin:0;
      width: calc(100% - 58px);
      padding:0 30px;
      border-radius: 20px 0 20px 0;
      height: 55px;
      .kicon{
        width:24px;
        height: 24px;
        background-size: 24px 24px;
        background-image: url('../../static/image/xx.png');
      }
      &.active{
        background-color: #776CD0;
        border-radius: 20px 0 20px 0;
        z-index: 9;
        position: absolute;
        .kicon{
          width:24px;
          height: 24px;
          background-size: 24px 24px;
          background-image: url('../../static/image/close2.png');
        }
      }
    }

  }

  .list{
    background-color: #776CD0;
    padding-bottom: 2px;
    padding-top: 30px;
    position: absolute;
    width: 100%;
    top: 34px;
    .option{
      color:#fff;
      margin:0;
      width: calc(100% - 34px);
      padding-right: 30px;
      margin-left: 2px;
      border-radius: 20px 0 20px 0;
      &:hover{
        background-color: #fff;
        color:#776CD0;

        .icon{

          background-image: url('../../static/image/m1.png');
        }
      }

      .icon{
        width:19px;
        height: 26px;
        background-size: 19px 26px;
        background-image: url('../../static/image/m2.png');
      }
    }
  }
}