.mark{
  width:100%;
  height: 100%;
  background:rgba(0,0,0,.5);
  position: fixed;
  top:0;
  left:0;
  z-index: 1001;
}

.content{
  background-image: url(../../static/image/game_detail_bg.png);
  background-size: 1106px 407px;
  position: fixed;
  left:50%;
  top:50%;
  width:1106px;
  height: 407px;
  transform: translate(-50%,-50%);
  z-index: 1002;
  overflow: hidden;
 

  .close{
    width:16px;
    height: 16px;
    background-image:url(../../static/image/close.png);
    background-size: 16px 16px;
    display: block;
    position: absolute;
    right:10px;
    top: 10px;
    cursor: pointer;
    z-index: 1002;
  }

  .box{
    width: 970px;
    height: 356px;
    margin:0 auto;
    margin-top: 50px;

    .icon{
      width:200px;
      height: 200px;
      margin-top: 40px;
      float:left;
      img{
        width: 100%;
        -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.1)));

      }
    }

    .con{
      width:668px;
      height: 356px;
      float:right;

      h3{
        color: #fff;
        text-align: center;
        font-size: 40px;
        font-weight: normal;
        margin-top: -21px;
      }

      .article{
        color: #fff;
        height: 200px;
        overflow-y: auto;
        line-height: 40px;
        margin-top: 2px;
        word-break: break-all;
        font-size: 22px;
        text-indent: 36px;
        font-family: "SimHei";
      }

      .btns{
        margin:11px auto;
        display: flex;
        justify-content: space-between;
        width: 613px;
        .item{
          width:195px;
          height: 39px;
          background-size: 195px 39px;
          cursor: pointer;
          &.fb-icon{
            background-image: url(../../static/image/icon_1.png);
          }
          &.google-icon{
            background-image: url(../../static/image/icon_2.png);
          }
          &.apple-icon{
            background-image: url(../../static/image/icon_3.png);
          }
        }
      }
    }
  }

}