*{
  margin:0;
  padding:0;
}
.banner1{
  width:100%;
  min-width: 1383px;
  height: 278px;
  background-image:url('../../static/image/banner1.png');
  background-size: 100% 278px;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 36px;

  .banner-icon{
    width:719px;
    height: 96px;
    background-image: url('../../static/image/banner1_w.png');
    background-size: 719px 96px;
    position: absolute;
    left:50%;
    top: 79px;
    margin-left:-359.5px;
  }

  .banner1-desc{
    text-align: center;
    position: absolute;
    top: 178px;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    color: #3b3b3b;

  }
}

.banner2{
  width:100%;
  min-width: 1383px;
  height: 278px;
  background-image:url('../../static/image/banner2.png');
  background-size: 100% 278px;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 36px;

  .banner2-time{
    width:237px;
    height: 98px;
    position: absolute;
    top:72px;
    left: 50%;
    margin-left:-118.5px;
    background:url('../../static/image/time.png') 237px 98px;
    

    
  }
  .title{
    color:#fff;
    position: absolute;
    bottom: 74px;
    font-size: 23px;
    text-align: center;
    display: block;
    width: 100%;
    letter-spacing: 2px;
    font-family: 'math';
  }
}

.games{
  
  position: relative;
  background-color: #F1F1F1;
  height: 348px;
  .games-con{
    width:100%;
    height: 312px;
    background: #fff;
    position: relative;
    left: 0;
    top: 34px;
  }
  .games-c{
    width: 1280px;
    position: absolute;
    left: 50%;
    margin-left: -640px;
    top: 34px;
  }
  .games-container{
    width: 1200px;
    margin-left: 40px;
    padding-right: 40px;
    height: 299px;
    margin-top: 13px;
    .item{
      width: 234px;
      height: 299px;
      flex: none;
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      translate: all .2s ease-out;
      align-items: center;
      justify-content: center;
      // &.active{
      //   width: 234px;
      //   height: 299px;
      //   .first{
      //     display:none;
      //     width: 176px;
      //     height: 230px;
      //     position: relative;
      //   }
      //   overflow:visible;
      //   border-radius: 0;
      //   img{
      //     border-radius: 10px;
      //     overflow: hidden;
      //   }
      //   .item-active{
      //     display:block;
      //     margin-left: -40px;
      //   }
      // }
      
      .first{
        width: 176px;
        height: 232px;
        display: block;
        .img{
          width: 100%;
        }
        .inner{
          position:absolute;
          top: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          margin:auto;
          // background:hsla(0,0%,100%,.5);
        }
      }
      .first-gray{
        width:176px;
        height: 230px;
        display: block;
        &.gray{
          width:176px;
          height: 230px;
          display: block;
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
          filter: gray;
          overflow: hidden;
          border-radius: 10px;
        }

        .gray{
          position:absolute;
          top: 0;
          
          left: 0;
          bottom: 0;
          height: 230px;
          width: 176px;
          background:rgba(219, 219, 219, 0.8);

          p{
            position: absolute;
            width: 100%;
            bottom:0;
            left: 0;
            line-height: 50px;
            text-decoration: underline;
            text-align: center;
          }
        }
        
      }
      // &:hover{
      //   width: 234px;
      //   height: 299px;
      //   .first{
      //     display:none;
      //     // width: 176px;
      //     // height: 230px;
      //     position: relative;
      //   }
      //   overflow:visible;
      //   border-radius: 0;
      //   img{
      //     border-radius: 10px;
      //     overflow: hidden;
      //   }
      //   .item-active{
      //     display:block;
      //     margin-left: -40px;
      //   }
      // }
      &:hover{
        width: 234px;
        height: 299px;
        .first{
          display:none;
          // width:176px;
          // height: 230px;
          position: relative;
        }
        overflow:visible;
        border-radius: 0;
        img{
          border-radius: 10px;
          overflow: hidden;
        }
        .item-active{
          display:block;
          cursor: pointer;
          // margin-left: -40px;
        }
      }

      
      .item-active{
        width:234px;
        height: 299px;
        background: url('../../static/image/game_active.png');
        position: absolute;
        display: none;
        overflow: hidden;
        // top: -37px;



        .send{
          width: 213px;
          height: 278px;
          margin-left: 10px;
          margin-top: 11px;
          position: absolute;
          overflow: hidden;
        }
      }

        .box{
          width:213px;
          height:48px;
          background-color:rgba(0,0,0,.5);
          bottom:10px;
          left:10px;
          position: absolute;
          border-radius: 0 0 10px 10px;
          color: #fff;
          text-align: right;
          display: flex;
          justify-content: space-between;

          .game-desc{
            width: calc(100% - 70px);
            font-size: 14px;
            padding-top: 8px;
            line-height: 18px;
            .game-des{
              font-size: 12px;
            }
          }

          .game-icon{
            width: 64px;
            height: 64px;
            position: absolute;
            bottom:3px;
            right:3px;
            img{
              width: 100%;
            }
          }
        }

      // &.active{
      //   width:234px;
      //   height: 299px;
      //   background: url('../../static/image/game_active.png');
      // }
    }
  }
  .swiper-button{
    position: absolute;
    cursor: pointer;
    top: auto;
    &::after {
      content: '\20';
    }
  }
  .swiper-button-prev{
    width:16px;
    height: 29px;
    background:url('../../static/image/left_btn.png') 16px 29px;
    left: -49px;
    bottom: 46PX;

  }
  .swiper-button-next{
    width:16px;
    height: 29px;
    background:url('../../static/image/right_btn.png') 16px 29px;
    right: -49px;
    bottom: 46PX;

  }
  
}

.game_icon_w{
  width:175px;
  height: 72px;
  position: absolute;
  background-image: url('../../static/image/game1.png');
  background-size: 175px 72px;
  right: 55px;
  top: 32px;
}