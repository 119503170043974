.mark{
  width:100%;
  height: 100%;
  position: fixed;
  left:0;
  top:0;
  background:rgba(0,0,0,.5);
}

.concat-bg{
  background-image: url(../../static/image/concat_bg.png);
  background-size: 407px 252px;
  position: fixed;
  left:50%;
  top:50%;
  width: 407px;
  height: 252px;
  transform: translate(-50%,-50%);
  z-index: 1002;
  overflow: hidden;
}

.box{
  width:324px;
  height: 187px;
  position: absolute;
  top:47px;
  text-align: center;
  color:#fff;
  left:50%;

  transform: translate(-50%,0%);
  font-family: "math";
  .concat-title{
    font-size: 20px;
    margin-bottom: 33px;
  }

  .concat-desc{
    font-size: 20px;
    line-height: 28px;
  }
  .btn{
    width:198px;
    height: 40px;
    line-height: 40px;
    color:#fff;
    font-size: 22px;
    text-align: center;
    background-image: url(../../static/image/btn_03.png);
    background-size: 198px 40px;
    margin:20px auto;
    cursor: pointer;
  }
}