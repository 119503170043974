.about-banner{
  width:100%;
  // background-image:url('../../public/img/banner2.png');
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  position: relative;
  margin-top: 36px;

  .box{
    width:326px;
    height: 104px;
    position: absolute;
    left:50%;
    top:50%;
    color:#fff;
    text-align: center;
    transform: translate(-50%,-50%);

    .tu{
      width:100%;
      display: block;
    }

    .desc{
      border-top:2px solid #fff;
      text-align: right;
      font-size: 28px;
      line-height: 40px;
      
    }
  }
}

.about{
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 400px);
  position: relative;
  padding: 63px 0;
  overflow: hidden;
 
  .logo{
    float:left;
    width:258px;
    height: 164px;
    
    img{
      width:100%;
    }
  }

  .content{
    float: right;
    width: 902px;
    padding: 20px 0;
    line-height: 36px;
    color: #3b3b3b;
    font-size: 18px;
  }
}

.container{
  width:1200px;
  margin:0 auto;
}