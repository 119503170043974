.mark{
  width:100%;
  height: 100%;
  position: fixed;
  left:0;
  top:0;
  z-index: 99;
  background:rgba(0,0,0,.6);
}

.login-box{
  width: 407px;
  height: 421px;
  background-image: url(../../static/image/login_box.png);
  background-size: 407px 421px;
  position: fixed;
  z-index: 100;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);

  .close{
    width:16px;
    height: 16px;
    background-size: 16px 16px;
    background-image: url(../../static/image/close.png);
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .container{
    position: absolute;
    top:55px;
    width:368px;
    left:20px;

    .item{
      width:368px;
      height: 60px;
      display: flex;
      align-items: center;
      background-color: #bcc6fe;
      border-radius: 20px 0 20px 0;
      margin-bottom: 14px;

      i.user-icon{
        width: 36px;
        height: 35px;
        background-image: url(../../static/image/l_1.png);
        background-size: 31px 35px;
        display: block;
        margin-left: 26px;
      }
      i.password-icon{
        width: 36px;
        height: 35px;
        background-image: url(../../static/image/l_2.png);
        background-size: 31px 35px;
        display: block;
        margin-left: 26px;
      }

      input {
        padding: 20px 0 20px 10px;
        width: 90%;
        margin-left: 10px;
        font-size: 16px;
        border:none;
        background-color: transparent;
        outline: none;
        color: #868895;
      }
    }

    .submit{
      width: 198px;
      height: 40px;
      line-height: 40px;
      background-image: url(../../static/image/btn_03.png);
      background-size: 198px 40px;
      margin:26px auto;
      text-align: center;
      color:#fff;
      cursor: pointer;
      font-size: 26px;
    }

    .other{

      .title{
        color:#bcc6fe;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        &::before{
          content:'';
          width: 115px;
          height: 2px;
          background-color:#9798EE;
          display: block;

        }
        &::after{
          content:'';
          width: 115px;
          height: 2px;
          background-color:#9798EE;
          display: block;

        }
      }

      .btns{
        width:250px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 21px auto; 

        .btn-item{
          width:55px;
          height: 55px;
        }
      }
    }
  }
}
.forget{
  position: absolute;
  text-decoration: underline;
  font-family: 'math';
  color: #3b3b3b;
  right: 7px;
  bottom: 161px;
  font-size: 17px;
}