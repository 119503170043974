
.footer{
  height: 70px;
  background: #3B3B3B;
  // border: 2px solid #776CD0;
  // margin-top: 32px;
  font-size: 14px;

  .footer-container{
    width:338px;
    line-height: 70px;
    color:#fff;
    margin:0 auto;
    a{
      color:#fff;
      text-decoration: none;
    }

    span{
      margin:0 9px;
    }
  }
}