.top-bg{
  height:58px;
  width: 100%;
  background-color:#776CD0;

  .bar{
    height:56px;
    border-radius: 0 0 20px 20px;
    background-color: #3B3B3B;
    position: relative;

    .logo{
      width:162px;
      height: 56px;
      position: absolute;
      left:104px;
      img {
        width:100%;
      }
    }
    /****导航样式***************************/
    .nav{
      position: absolute;
      height: 56px;
      top:0;
      left:50%;
      transform: translate(-50%, 0);

      ul li{
        float: left;
        a{
          display: inline-block;
          height: 56px;
          line-height: 56px;
          text-align: center;
          color:#fff;
          text-decoration: none;
          padding:0 22px;
          font-size: 20px;
          font-family: 'math';
          &:hover{
            background-color: #776CD0;
            border-radius: 10px 10px 0 0;
           
          }

          &.active{
            background-color: #776CD0;
            border-radius: 10px 10px 0 0;
 
          }
        }
      }
      
    }
    ul li.language{
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    /****用户显示样式*********************************/

    .user{
      // width:160px;
      height: 56px;
      position:absolute;
      right: 14%;

      .user-box{
        display: flex;
        height: 56px;
        align-items: center;
        color: #fff;
        cursor: pointer;
        color:#776ECC;
        span.login{
          margin-top: 10px;
          text-decoration: underline;
        }
        i.icon{
          width: 18px;
          height: 20px;
          background-image: url(../../static/image/user.png);
          background-size: 18px 20px;
          display: block;
          margin-right: 10px;
          margin-top: 10px;
        }

        span.user-name{
          color:#fff;
          margin-left: 10px;
        }

        span.logout{
          color:#776CD0;
          margin-left: 10px;
          cursor: pointer;
        }

      }
    }

    /***切换语言样式****************************************/
    .language{
      position: absolute;
      right: 5%;
      top: 21px;
      cursor: pointer;

      .language-show{
        width: 82px;
        height: 22px;
        line-height: 22px;
        background: #776CD0;
        color:#fff;
        text-align: center;
        border-radius: 5px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .icon{
          width: 10px;
          height: 10px;
          background-image: url('../../static/image/up.png');
        }

        &.down{
          .icon{
            width: 10px;
            height: 10px;
            background-image: url('../../static/image/down.png');
          }
        }
      }
      .language-list{
        position: absolute;
        z-index: 99;
        top: 26px;
        ul{
          background: #333;
          width: 82px;
          li{
            color:#fff;
            line-height: 35px;
            height: 35px;
            text-align: left;
            cursor: pointer;
            text-indent: 5px;

            &:hover{
              background: #776CD0;
            }
          }
        }
      }
    }
  }
}
*{
  margin:0;
  padding:0;
}
li{
  list-style: none;
}