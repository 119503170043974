body {
  margin: 0;
  font-family:'Adobe 黑体 Std';
  min-width: 1200px;
}

*{
  margin:0;
  padding:0;
}

div::-webkit-scrollbar
{
	width: 5px;
	height: 5px;
	background-color: #F5F5F5;
}
 
/*定义滚动条轨道 内阴影+圆角*/
div::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
 
/*定义滑块 内阴影+圆角*/
div::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}